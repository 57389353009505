import React, { useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import RecentPosts from "../components/RecentPosts";
import ReactPaginate from "react-paginate";
import {useMain} from "../states/MainContext";
import {Helmet} from "react-helmet";
import {useNavigate, useParams} from "react-router-dom";
import Footer from "../components/Footer";
import {useLang} from "../states/LangContext";
import Items from "../components/Items";
import {ShimmerContentBlock, ShimmerPostItem} from "react-shimmer-effects";
import useWindowSize from "../hooks/WindowSize";


function Blog() {

    const {blogLoader,blogs,setBlogLoader,setBlogs,setBlogList,blogMedia,setBlogMedia} = useMain()

    const {lang,setLang,languages} =useLang()
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const params = useParams()
    const navigate = useNavigate()
    const itemsPerPage = 6;
    const windowSize = useWindowSize()
    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(blogs.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(blogs.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);
    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(blogs.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(blogs.length / itemsPerPage));
    } , [blogs])
    useEffect(()=>{
         if(!params.lang){
             navigate(`/${lang}/`)
         }else{
             setLang(params.lang)
             sessionStorage.setItem("go_lang",params.lang)
         }
    },[])
    useEffect(()=>{
        let langtag = languages.filter(item => item.code === lang)
        setBlogLoader(true)
        fetch(`https://blog.gotocure.com/wp-json/wp/v2/posts?per_page=9999&tags=${langtag[0].tag}`).then(res => res.json())
            .then(data => {
                setBlogs(data);
                setBlogList(data)
            })
            .then(() => {
                setBlogLoader(false)
            })
        fetch("https://blog.gotocure.com/wp-json/wp/v2/media?per_page=100")
            .then(response => response.json())
            .then(data => {
                setBlogMedia(data)
            })
    },[lang])
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % blogs.length;
        setItemOffset(newOffset);
        document.documentElement.scrollTo(0, 0);
    };
    return (
        <>
            {<Helmet>
                <meta charSet="utf-8"/>
                <title>Gotocure Blog</title>
                <meta name="description" content="Here are the answers to all your health questions." />
            </Helmet>}
            <Container className={"mt-3"}>
            <Row >
                <Col md={8} >
                    {
                        blogLoader ?

                             <div style={{marginBottom: "20px"}}>
                                 { windowSize > 762 ?
                                     <div>
                                         <ShimmerContentBlock title text thumbnailWidth={150} thumbnailHeight={100}/>
                                         <ShimmerContentBlock title text thumbnailWidth={150} thumbnailHeight={100}/>
                                         <ShimmerContentBlock title text thumbnailWidth={150} thumbnailHeight={100}/>
                                         <ShimmerContentBlock title text thumbnailWidth={150} thumbnailHeight={100}/>
                                         <ShimmerContentBlock title text thumbnailWidth={150} thumbnailHeight={100}/>
                                     </div> :
                                     <div style={{marginBottom: "20px"}}>
                                     <ShimmerPostItem card title cta />
                                     <ShimmerPostItem card title cta />
                                     <ShimmerPostItem card title cta />
                                     <ShimmerPostItem card title cta />
                                     </div>
                                 }
                             </div>
                            :
                            <>
                                <Items currentItems={currentItems} media={blogMedia}/>
                                <ReactPaginate
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    pageCount={pageCount}
                                    previousLabel="<"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                /></>
                    }

                </Col>
                <Col md={4}>
                    <RecentPosts />

                </Col>
            </Row>
            </Container>
            <Footer />
        </>

    );
}

export default Blog;