import React, {useState} from 'react';
import style from './comment.module.css'
import {Col, Form, Row} from "react-bootstrap";
import LangText from "../../hooks/langText";

const Index = ({id}) => {
    const [name,setName] = useState()
    const [email,setEmail] = useState()
    const [comment,setComment] = useState()
    const formPost = (e) =>{
        e.preventDefault()
        const formData = new FormData()
        formData.append("author",name)
        formData.append("email",email)
        formData.append("comment",comment)
        formData.append("comment_post_ID",id)
        formData.append("submit","Submit+Comment")
        formData.append("comment_parent",0)
        fetch("https://blog.gotocure.com/wp-comments-post.php",{method:"post",body:formData}).
        then(response=> console.log(response))
    }
    return (
        <div className={style.comment}>
            <Form method="post" onSubmit={(e)=>formPost(e)}>
                <Row>
                    <Col md={"6"}>
                        <Form.Group className="mb-3" >
                            <label className={style.formLabel} htmlFor={"name"}>{LangText("İsim")}</label>
                            <input type="text" className={style.formInput} id={"name"} placeholder="Enter Name" onChange={(e)=>setName(e.target.value)}  />

                        </Form.Group>
                    </Col>
                    <Col md={"6"}>
                        <Form.Group className="mb-3" >
                            <label className={style.formLabel} htmlFor={"email"}>{LangText("E-Posta")}</label>
                            <input type="email" className={style.formInput} id={"email"} placeholder="Enter email" onChange={(e)=>setEmail(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col md={"12"}>
                        <Form.Group>
                            <label className={style.formLabel} htmlFor={"text"}>{LangText("Yorum")}</label>
                            <textarea className={style.formTextarea} id={"text"} rows="3" placeholder={"Enter your comment"} onChange={(e)=>setComment(e.target.value)}></textarea>
                        </Form.Group>
                    </Col>
                </Row>
                <button className={`btn ${style.formButton} mb-3`}>{LangText("Gönder")}</button>
            </Form>

        </div>
    );
};

export default Index;