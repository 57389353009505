import {Link} from "react-router-dom";
import style from "../RecentPosts/recent.module.css";
import {IoIosArrowRoundForward} from "react-icons/io";
import React from "react";
import LangText from "../../hooks/langText";
import {useLang} from "../../states/LangContext";
import {useMain} from "../../states/MainContext";
import {ShimmerText} from "react-shimmer-effects";


function Categories (){
    const {lang} = useLang()
    const {blogLoader} = useMain()
    const categoryList = [{
        "tr": [{
            "id": 1,
            "name": "Diş Tedavileri",
            "slug": "dis-tedavileri",
        },{
            "id": 2,
            "name": "Göz Tedavileri",
            "slug": "goz-tedavileri",
        },{
            "id": 3,
            "name": "Jinekoloji",
            "slug": "jinekoloji",
        },{
            "id": 4,
            "name": "Obezite Cerrahisi",
            "slug": "obezite-cerrahisi",
        },{
            "id":5,
            "name":"Onkoloji",
            "slug":"onkoloji",
        },{
            "id": 6,
            "name": "Plastik Cerrahi",
            "slug": "plastik-cerrahi",
        },{
            "id": 7,
            "name":"Saç Tedavileri",
            "slug":"sac-tedavileri",
        }
        ],"en":[
            {
                "id": 1,
                "name": "Dental Treatments",
                "slug": "dental-treatments",
            },
            {
                "id": 2,
                "name": "Eye Treatment",
                "slug": "eye-treatment",
            },
            {
                "id": 3,
                "name": "Gynecology",
                "slug": "gynecology",
            },
            {
                "id": 4,
                "name": "Obesity Surgery",
                "slug": "obesity-surgery",
            },
            {
                "id":5,
                "name":"Oncology",
                "slug":"oncology",
            },
            {
                "id": 6,
                "name": "Plastic Surgery",
                "slug": "plastic-surgery",
            },
            {
                "id": 7,
                "name":"Organ transplant",
                "slug":"organ-transplant",
            }

        ],"ar":[
            {
                "id": 1,
                "name":"أمراض النساء",
                "slug":"%d8%a3%d9%85%d8%b1%d8%a7%d8%b6-%d8%a7%d9%84%d9%86%d8%b3%d8%a7%d8%a1",
            },
            {
                "id": 2,
                "name":"جراحة السمنة",
                "slug":"%d8%ac%d8%b1%d8%a7%d8%ad%d8%a9-%d8%a7%d9%84%d8%b3%d9%85%d9%86%d8%a9",
            },
            {
                "id": 3,
                "name":"جراحة تجميلية",
                "slug":"%d8%ac%d8%b1%d8%a7%d8%ad%d8%a9-%d8%aa%d8%ac%d9%85%d9%8a%d9%84%d9%8a%d8%a9",
            },
            {
                "id": 4,
                "name":"زراعة الاعضاء",
                "slug":"%d8%b2%d8%b1%d8%a7%d8%b9%d8%a9-%d8%a7%d9%84%d8%a7%d8%b9%d8%b6%d8%a7%d8%a1",
            },{
                "id":5,
                "name":"علاج الأسنان",
                "slug":"%d8%b9%d9%84%d8%a7%d8%ac-%d8%a7%d9%84%d8%a3%d8%b3%d9%86%d8%a7%d9%86",
            },{
                "id": 6,
                "name":"علاجات الشعر",
                "slug":"%d8%b9%d9%84%d8%a7%d8%ac%d8%a7%d8%aa-%d8%a7%d9%84%d8%b4%d8%b9%d8%b1",
            },{
                "id": 7,
                "name":"علاجات العين",
                "slug":"%d8%b9%d9%84%d8%a7%d8%ac%d8%a7%d8%aa-%d8%a7%d9%84%d8%b9%d9%8a%d9%86",
            },{
                "id": 8,
                "name":"علم الأورام",
                "slug":"%d8%b9%d9%84%d9%85-%d8%a7%d9%84%d8%a3%d9%88%d8%b1%d8%a7%d9%85",
            }
        ],"ru":[
            {
                "id": 1,
                "name":"гинекология",
                "slug":"%d0%b3%d0%b8%d0%bd%d0%b5%d0%ba%d0%be%d0%bb%d0%be%d0%b3%d0%b8%d1%8f",
            },{
                "id": 2,
                "name":"Лечение волос",
                "slug":"%d0%bb%d0%b5%d1%87%d0%b5%d0%bd%d0%b8%d0%b5-%d0%b2%d0%be%d0%bb%d0%be%d1%81",
            },
            {
                "id": 3,
                "name":"онкология",
                "slug":"%d0%be%d0%bd%d0%ba%d0%be%d0%bb%d0%be%d0%b3%d0%b8%d1%8f",
            },{
                "id":4,
                "name":"Пластическая хирургия",
                "slug":"%d0%bf%d0%bb%d0%b0%d1%81%d1%82%d0%b8%d1%87%d0%b5%d1%81%d0%ba%d0%b0%d1%8f-%d1%85%d0%b8%d1%80%d1%83%d1%80%d0%b3%d0%b8%d1%8f",
            },{
                "id": 5,
                "name":"Стоматологические процедуры",
                "slug":"%d1%81%d1%82%d0%be%d0%bc%d0%b0%d1%82%d0%be%d0%bb%d0%be%d0%b3%d0%b8%d1%87%d0%b5%d1%81%d0%ba%d0%b8%d0%b5-%d0%bf%d1%80%d0%be%d1%86%d0%b5%d0%b4%d1%83%d1%80%d1%8b",
            },{
                "id": 6,
                "name":"трансплантация органа",
                "slug":"%d1%82%d1%80%d0%b0%d0%bd%d1%81%d0%bf%d0%bb%d0%b0%d0%bd%d1%82%d0%b0%d1%86%d0%b8%d1%8f-%d0%be%d1%80%d0%b3%d0%b0%d0%bd%d0%b0",
            },{
                "id": 7,
                "name":"Уход за глазами",
                "slug":"%d1%83%d1%85%d0%be%d0%b4-%d0%b7%d0%b0-%d0%b3%d0%bb%d0%b0%d0%b7%d0%b0%d0%bc%d0%b8",
            },{
                "id": 8,
                "name":"Хирургия ожирения",
                "slug":"%d1%85%d0%b8%d1%80%d1%83%d1%80%d0%b3%d0%b8%d1%8f-%d0%be%d0%b6%d0%b8%d1%80%d0%b5%d0%bd%d0%b8%d1%8f",
            }
        ],"az":[
            {
                "id":1,
                "name":"Ginekologiya",
                "slug":"ginekologiya",
            },{
                "id":2,
                "name":"Göz müalicələri",
                "slug":"goz-mualic%c9%99l%c9%99ri",
            },{
                "id":3,
                "name":"Obezlik cərrahiyyəsi",
                "slug":"obezlik-c%c9%99rrahiyy%c9%99si",
            },{
                "id":4,
                "name":"Onkologiya",
                "slug":"onkologiya",
            },{
                "id":5,
                "name":"Orqan transplantasiyası",
                "slug":"orqan-transplantasiyasi",
            },{
                "id":6,
                "name":" Plastik cərrahiyyə",
                "slug":"plastik-c%c9%99rrahiyy%c9%99",
            },{
                "id":7,
                "name":"Saç müalicələri",
                "slug":"sac-mualic%c9%99l%c9%99ri",
            }
        ]

    }]
    let categoryListMap = categoryList[0][lang]
    return(
    <>
        <div className={"mt-3"}>
            <h3>{LangText("Kategoriler")}</h3>
            <hr/>
            {
                blogLoader ? <div className={"mt-3 mb-3"} > <ShimmerText line={6} /></div>:
                    categoryListMap.map((category,index) => {
                        return (
                            <Link key={index} to={`/${lang}/category/${decodeURI(category.slug)}`} className={style.recentText} >
                                <h6>
                                    <IoIosArrowRoundForward />
                                    {category.name}
                                </h6>
                            </Link>
                        )
                    })
            }
        </div>
    </>
    )
}

export default Categories
