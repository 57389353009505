import React, {useEffect, useMemo, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import RecentPosts from "../components/RecentPosts";
import ReactPaginate from "react-paginate";
import  {useMain} from "../states/MainContext";
import {useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import Items from "../components/Items";
import {useLang} from "../states/LangContext";
import {ShimmerContentBlock, ShimmerPostItem} from "react-shimmer-effects";
import useWindowSize from "../hooks/WindowSize";




function BlogFilter() {
    useEffect(()=>{
        if(!params.lang){
            navigate(`/${lang}/`)
        }else{
            setLang(params.lang)
            sessionStorage.setItem("go_lang",params.lang)
        }
    },[])

    const {blogs,blogCategories,setBlogCategories,setBlogLoader,setBlogs,setBlogList,blogMedia,setBlogMedia} = useMain()
    const {languages,lang,setLang} = useLang()
    const navigate = useNavigate()
    const  [loader,setLoader] = useState(false)
    const params = useParams();
    const categorySlug = params.category;
    const paramslang = params.lang
    useEffect(()=>{


            if(blogCategories.length === 0 ){
                setLoader(true)
                fetch("https://blog.gotocure.com/wp-json/wp/v2/categories?per_page=100").then(res => res.json())
                    .then(data => {
                        setBlogCategories(data.filter(item => item.name !== "Uncategorized"))
                    }).then(() => setLoader(false))
            }
            if(blogMedia.length ===0){
                fetch("https://blog.gotocure.com/wp-json/wp/v2/media?per_page=100")
                    .then(response => response.json())
                    .then(data => {
                        setBlogMedia(data)
                    })
            }

            if(blogs.length ===0){
                let langtag = languages.filter(item => item.code === paramslang)
                setLoader(true)
                setBlogLoader(true)
                setLoader(true)
                fetch(`https://blog.gotocure.com/wp-json/wp/v2/posts?per_page=9999&tags=${langtag[0].tag}`).then(res => res.json())
                    .then(data => {
                        setBlogs(data);
                        setBlogList(data)
                    })
                    .then(() => {
                        setLoader(false)
                        setBlogLoader(false)
                        setLoader(false)
                    })
            }


    },[])
    const categorySet = () =>{

        let cat = blogCategories.find(cat => decodeURI(cat.slug) === decodeURI(categorySlug))
        return cat
    }
    const filterBlogs = () =>{
        let fb =(blogs.filter(blog => blog?.categories.includes(category?.id)))
        return fb
    }
    const category = useMemo(()=>categorySet(),[blogCategories,categorySlug])
    const filteredBlog = useMemo(()=>filterBlogs(),[category,blogs])

    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 6;
    const windowSize = useWindowSize()
    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(filteredBlog.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(filteredBlog.length / itemsPerPage));
    }, [itemOffset, itemsPerPage,categorySlug]);
    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(filteredBlog.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(filteredBlog.length / itemsPerPage));
    } , [filteredBlog])
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % filteredBlog.length;
        setItemOffset(newOffset);
        document.documentElement.scrollTo(0, 0);
    };
    return (
        <>
            {<Helmet>
                <meta charSet="utf-8"/>
                <title>{category ? category.name + " | Gotocure Blog" : "Gotocure Blog"}</title>

            </Helmet>}
            <Container className={"mt-3"}>
                <Row >
                    <Col md={8} >
                        {
                            loader ?
                                <div style={{marginBottom: "20px"}}>
                                    { windowSize > 762 ?
                                        <div>
                                            <ShimmerContentBlock title text thumbnailWidth={150} thumbnailHeight={100}/>
                                            <ShimmerContentBlock title text thumbnailWidth={150} thumbnailHeight={100}/>
                                            <ShimmerContentBlock title text thumbnailWidth={150} thumbnailHeight={100}/>
                                            <ShimmerContentBlock title text thumbnailWidth={150} thumbnailHeight={100}/>
                                            <ShimmerContentBlock title text thumbnailWidth={150} thumbnailHeight={100}/>
                                        </div> :
                                        <div style={{marginBottom: "20px"}}>
                                            <ShimmerPostItem card title cta />
                                            <ShimmerPostItem card title cta />
                                            <ShimmerPostItem card title cta />
                                            <ShimmerPostItem card title cta />
                                        </div>
                                    }
                                </div>
                                :
                                <>
                                    <Items currentItems={currentItems} media={blogMedia}/>
                                    <ReactPaginate
                                        nextLabel=">"
                                        onPageChange={handlePageClick}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        pageCount={pageCount}
                                        previousLabel="<"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                    /></>
                        }

                    </Col>
                    <Col md={4}>
                    <RecentPosts />

                    </Col>
                </Row>
            </Container>

        </>

    );
}

export default BlogFilter;