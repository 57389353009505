import React, { useState} from 'react';
import {IoIosArrowRoundForward} from "react-icons/io";
import style from "./recent.module.css";
import {Button,  Form, FormControl, InputGroup} from "react-bootstrap";
import {FaSearch} from "react-icons/fa";
import  {useMain} from "../../states/MainContext";
import {Link} from "react-router-dom";
import Categories from "../Categories";
import LangText from "../../hooks/langText";
import {useLang} from "../../states/LangContext";
import {ShimmerText} from "react-shimmer-effects";


function Index() {
    const {setBlogs,blogList,blogLoader} = useMain()
    const {lang} = useLang()
    const newList = blogList.slice(0,5)
    const filteredBlogs =(e) => {
        let filter = blogList.filter(blog => {
            return blog.title.rendered.toLowerCase().includes(e)
        })
        setBlogs(filter)
    }

    return (
        <>
            <div>
                <Form className={"mb-3"}>
                    <InputGroup className="mb-3">

                        <FormControl
                            placeholder={LangText("Bloglarda ara")}
                            aria-describedby="basic-addon1"
                            className={style.searchBar}
                            onChange={(e) => filteredBlogs(e.target.value.toLowerCase())}
                        />
                        <Button  variant="outline-success" id="button-addon1">
                            <FaSearch/>
                        </Button>
                    </InputGroup>
                </Form>
            </div>
            <div className={style.recentPost}>
                <h3>{LangText("Son Yazılar")}</h3>
                <hr/>
                {
                  blogLoader ? <div className={"mt-3 mb-3"} >
                      <ShimmerText line={6} />

                  </div>:  newList.map((blog,index) => {
                        return (
                            <h6 key={index}>
                                <Link to={`/${lang}/${blog.id}/${blog.slug}`} className={style.recentText}>

                                    <IoIosArrowRoundForward/> {blog.title.rendered}
                                </Link>
                            </h6>
                        )
                    })

                }






            </div>

            <Categories   />

        </>
    );
}

export default Index;