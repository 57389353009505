import React from 'react';
import {Container} from "react-bootstrap";

const Error = () => {
    return (

            <Container style={{display:"flex",alignItems:"center",justifyContent:"center", width:"100%",height:"50vh"}}>
                NOTHING HERE
            </Container>

    );
};

export default Error;