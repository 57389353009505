import {createContext, useContext, useEffect, useState} from "react";

const LangContext = createContext()


export const LangProvider = ({children}) =>{
    const [lang,setLang] = useState(sessionStorage.getItem("go_lang") ? sessionStorage.getItem("go_lang") : "en");
    const languages = [{"code" : "tr", "text":"Türkçe","tag":1659,"lang_id":96},{"code":"en","text":"English","tag":1661,"lang_id":25},{"tag":1663,"code":"ar","text":"العربية","lang_id":2},{"code":"ru","text":"Русский","tag":1665,"lang_id":83},{"code":"az","text":"Azərbaycan","tag":1667,"lang_id":4}]
    const [langMessage,setLangMessage] = useState([])
    useEffect(()=>{
            const formData = new FormData();
            setLangMessage([])
            formData.append("langText", 1);
            formData.append("lang", lang);
            fetch(process.env.REACT_APP_SYSTEM_URL, {
                method: 'POST',
                body:formData
            }).then(res => res.json()).then(res => setLangMessage(res))
    },[lang])
    const values = {
        lang,setLang,languages,langMessage,setLangMessage
    }
    return (
        <LangContext.Provider value={values}>{children}</LangContext.Provider>
    )
}

export const useLang = () => useContext(LangContext)