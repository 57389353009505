import React, {useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import { useEffect } from 'react'
import {Col, Container, Row} from "react-bootstrap";
import Content from "../components/Content";
import {Helmet} from "react-helmet";
import OfferBox from "../components/OfferBox";
import {useLang} from "../states/LangContext";
import useWindowSize from "../hooks/WindowSize";
import {ShimmerPostDetails} from "react-shimmer-effects";





function BlogDetail() {

    let params = useParams();
    const navigate = useNavigate()
    const [loader,setLoader]= useState(false)
    const {lang,setLang} = useLang()
    const [detail,setDetail] = useState([])
    useEffect(()=>{
        setDetail([])
        setLoader(true)
        fetch(`https://blog.gotocure.com/wp-json/wp/v2/posts/${params.id}`)
            .then((res)=> {

                if(!res.ok) navigate("/error")
                else return res.json()
            })
            .then(data=>{ setDetail(data) })
            .then(() =>setLoader(false))
    },[params.id])
    useEffect(()=>{
        if(lang !== params.lang){
            setLang(params.lang)
            sessionStorage.setItem("go_lang",params.lang)
        }
    },[])

    const windowSize = useWindowSize();
    const url = window.location.href

    return (
        <>{(detail.id && !loader) ?
            <>
                {<Helmet>
                    <meta charSet="utf-8"/>
                    <title>{detail.title.rendered ? detail.title.rendered + " | Gotocure Blog" : "Detail | Gotocure Blog"}</title>
                    <meta name="description" content={detail.excerpt.rendered}/>
                    <meta name="twitter:card" content="summary"/>
                    <meta name="twitter:site" content="@gotocure"/>
                    <meta property="og:url" content={url}/>
                    <meta property="og:title" content={detail.title.rendered + " | Gotocure Blog"}/>
                    <meta property="og:description" content={detail.excerpt.rendered}/>
                    <meta property="og:image" content={"https://gotocure.com/images/logo.png"}/>
                    <meta property="og:url" content={url}/>
                    <meta property="og:type" content="article"/>
                    <meta property="og:title" content={detail.title.rendered + " | Gotocure Blog"}/>
                    <meta property="og:description" content={detail.excerpt.rendered}/>
                    <meta property="og:image" content={ "https://gotocure.com/images/logo.png"}/>
                </Helmet>}

                <Container className={"mt-3"}>
                    <Row>
                        <Col md={9}>
                            <Content blog={detail} />
                        </Col>
                    </Row>
                </Container>
                { (windowSize.width < 768 )   &&
                    <OfferBox/>
                }
            </> : <Container className={"mt-5"}>
                <Col md={9}>
                    <ShimmerPostDetails  cta variant="SIMPLE" />
                </Col>
            </Container>
        }
        </>
    );
}

export default BlogDetail;