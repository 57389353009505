import {useState, useContext,createContext} from 'react';

const MainContext = createContext()

export const MainProvider  =({children})=>{
    const [blogs,setBlogs] = useState([])
    const [blogList,setBlogList] = useState([])
    const [blogLoader,setBlogLoader] = useState(true)
    const [blogCategories,setBlogCategories] = useState([])
    const [blogMedia,setBlogMedia] = useState([])



    const values = {blogs,
        setBlogs,blogList,setBlogList,blogLoader,setBlogLoader,blogCategories,setBlogCategories,blogMedia,setBlogMedia}
    return <MainContext.Provider value={values}>{children}</MainContext.Provider>
}

export const useMain = () => useContext(MainContext)