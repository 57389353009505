import React, {useEffect, useState} from 'react';
import style from './content.module.css'
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    VKShareButton,
    VKIcon,
    WhatsappIcon,
    WhatsappShareButton,
    TelegramIcon,
    TelegramShareButton,
} from "react-share";
import Comment from "../Comment";
import { ru,enGB,tr,ar,az } from 'date-fns/locale';
import { format } from 'date-fns'
import  {useMain} from "../../states/MainContext";
import {useLang} from "../../states/LangContext";

const Index = ({blog}) => {
    const url = decodeURI( window.location.href);
    const {blogCategories, setBlogCategories} = useMain();
    const {lang} = useLang()
    let date = new Date(blog.date);
    useEffect(()=>{
       if(blogCategories.length === 0 ){
           fetch("https://blog.gotocure.com/wp-json/wp/v2/categories?per_page=100").then(res => res.json())
               .then(data => {
                   setBlogCategories(data.filter(item => item.name !== "Uncategorized"))
               })
       }
    },[])
    const category = blogCategories.find(category => category.id === blog.categories[0]);
    switch (lang){
        case 'ru':
             date = format(new Date(blog.date), 'LLLL dd, yyyy', { locale: ru });
            break;
        case 'en':
            date = format(new Date(blog.date), 'LLLL dd, yyyy', { locale: enGB });
                break;
        case 'tr':
            date = format(new Date(blog.date), 'LLLL dd, yyyy', { locale: tr });
            break;
        case 'ar':
            date = format(new Date(blog.date), 'LLLL dd, yyyy', { locale: ar });
            break;
        case 'az':
            date = format(new Date(blog.date), 'LLLL dd, yyyy', { locale: az });
            break;
    }

    return (
        <article>
            <div>
                <div className={style.blogInfos}>
                    <h1 className={style.title}>{blog.title.rendered}</h1>
                    <div className={style.blogDate}>{date}</div>
                </div>
                <div className={style.blogDesc}>

                    <div className={style.blogDateMobil}>{date}</div>
                    {category && <div className={style.blogCategory}>{category?.name} </div>}
                </div>

            </div>
            <div  dangerouslySetInnerHTML={{__html:blog.content.rendered}}></div>
            <hr/>
            <div className={style.share}>
                <h3>Share a blog </h3>
                <div className={style.shareButtons}>
                    <FacebookShareButton url={url} >
                        <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                    <TwitterShareButton url={url} >
                        <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>
                    <LinkedinShareButton url={url} >
                        <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton>
                    <VKShareButton url={url} >
                        <VKIcon size={32} round={true} />
                    </VKShareButton>
                    <WhatsappShareButton url={url} >
                        <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                    <TelegramShareButton url={url} >
                        <TelegramIcon size={32} round={true} />
                    </TelegramShareButton>
                </div>
            </div>
            <hr/>
            <div>
                <h3> Share a comment</h3>
                <div className={style.comment}>
                    <Comment id={blog.id} />
                </div>
            </div>
        </article>

    );
};

export default Index;