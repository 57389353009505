import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import style from "./footer.module.css";
import {BsInstagram, BsLinkedin, BsTwitter,BsFacebook,BsYoutube} from "react-icons/bs";
import LangText from "../../hooks/langText";
const Index = () => {
    return (
        <footer className={style.footer}>
            <div className={style.footerTop}>
                <Container>
                    <Row>
                        <Col lg={3} md={6} className={style.footerInfo}>
                            <a href={"https://gotocure.com"}>
                                <img src="https://gotocure.com/images/logow.png" alt="logo" className={style.logo}/>
                            </a>
                            <p className={style.phone}>+90 212 281 40 40</p>
                            <p className={style.phone}>Profilo Mall No:33 Sisli/ISTANBUL </p>
                            <div className={style.safe}>
                                <img src="https://gotocure.com/images/ssl.png" alt="TrustSafe" className={style.logo} width={"40%"}/>
                                <img src="https://gotocure.com/images/cloud.png" alt="TrustSafe" className={style.logo} width={"35%"}/>

                            </div>
                        </Col>
                        <Col lg={2} md={6} className={style.footerLinks}>
                            <h4 className={style.footerTitle}>{LangText("Kurumsal")}</h4>
                            <ul className={style.footerTable}>
                                <li>
                                    <a href={"https://gotocure.com/hakkimizda"}>{LangText("Hakkımızda")}</a>
                                </li>
                                <li>
                                    <a href={"https://gotocure.com/gotocure-is-ortakligi"}>{LangText("Gotocure İş Ortaklığı")}</a>
                                </li>
                                <li>
                                    <a href={"https://gotocure.com/gotocure-kariyer"}>{LangText("Gotocure Kariyer")}</a>
                                </li>
                                <li>
                                    <a href={"https://gotocure.com/iletisim"}>{LangText("İletişim")}</a>
                                </li>
                            </ul>
                        </Col>
                        <Col lg={2} md={6} className={style.footerColumn}>
                            <h4 className={style.footerTitle}>{LangText("İçerikler")}</h4>
                            <ul className={style.footerTable}>

                                <li>
                                    <a href={"https://gotocure.com/cure"}>{LangText("Tedaviler")}</a>
                                </li>
                                <li>
                                    <a href={"https://gotocure.com/clinic"}>{LangText("Klinik")}</a>
                                </li>
                                <li>
                                    <a href={"https://gotocure.com/kesfet"}>{LangText("Keşfet")}</a>
                                </li>
                                <li>
                                    <a href={"https://gotocure.com/blog"}>{LangText("Blog")}</a>
                                </li>
                            </ul>
                        </Col>
                        <Col lg={2} md={6} className={style.footerColumn}>
                            <h4 className={style.footerTitle}>{LangText("Politikalar")}</h4>
                            <ul className={style.footerTable}>
                                <li>
                                    <a href={"https://gotocure.com/politikalar"}>{LangText("Kullanıcı Sözleşmesi")}</a>
                                </li>
                                <li>
                                    <a href={"https://gotocure.com/politikalar"}>{LangText("Gizlilik Politikası")}</a>
                                </li>
                                <li>
                                    <a href={"https://gotocure.com/sitemap.xml"}>{LangText("Site Haritası")}</a>
                                </li>

                            </ul>
                        </Col>
                        <Col lg={3} md={6} className={style.footerInfo2}>
                            <h2>{LangText("Bizi takip edin")}</h2>
                            <div className={style.socialLinks}>
                                <a className={style.instagram} href={"https://www.instagram.com/gotocure/"}><BsInstagram width={20} height={20}/></a>
                                <a className={style.facebook} href={"https://www.facebook.com/Gotocure/"}><BsFacebook width={20} height={20}/></a>
                                <a className={style.youtube} href={"https://www.youtube.com/channel/UCCW1gHx9idC-qsOsLGT6OVQ"}><BsYoutube width={20} height={20}/></a>
                                <a className={style.linkedin} href={"https://www.linkedin.com/company/gotocure"}><BsLinkedin width={20} height={20}/></a>
                                <a className={style.twitter} href={"https://twitter.com/gotocure"}><BsTwitter width={20} height={20}/></a>
                            </div>
                            <div className={style.newsletter}>
                                <form>
                                    <input type="text" placeholder={LangText("E-Bülten")}/>
                                    <button>{LangText("Abone ol")}</button>
                                </form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </footer>
    );
};

export default Index;