import React, {useEffect, useState} from 'react';
import {Button, Container, Form, FormControl, InputGroup, Nav, Navbar, NavDropdown, Row} from "react-bootstrap";
import style from './header.module.css'
import {FaSearch} from "react-icons/fa";
import {BsGlobe} from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import LangText from "../../hooks/langText";
import {useLang} from "../../states/LangContext";



function Index() {
    const navigate = useNavigate();
    const [clinics,setClinics] = useState([""])
    const [doctors,setDoctors] = useState([""])
    const [categories,setCategories] = useState([""])
    const [showSearch,setShowSearch] = useState(false)
    const [searchList,setSearchList] = useState([""])
    const [showMobileHeader,setShowMobileHeader] = useState(false)


    const  {lang,setLang,languages} = useLang()

    let selectedLanguage = languages.filter(language => language.code === lang)
    const changeLanguage = (code) =>{
        setLang(code)
        navigate(`/${code}/`)
    }

    const searchAll = (e) =>{
        e.length > 0 ? setShowSearch(true) : setShowSearch(false)
        let formData = new FormData()
        const lang_id = languages.find(item=> item.code === lang)
        formData.append("lang_id",lang_id.lang_id)
        formData.append("q",e)
        fetch("https://api.gotocure.com/test/search",{method:"post",body:formData})
            .then(response=> response.json())
            .then(data => setSearchList(data))
    }
    useEffect(()=>{
        setClinics(searchList["clinics"])
        setDoctors(searchList["doctors"])
        setCategories(searchList["categorys"])
    },[searchList])
    return (
        <Navbar className={"navbar sticky-top navbar-expand-lg navbar-light bg-white"} >
            {/* header desktop*/}
            <Container className={`container d-none d-md-block`}>

                <div className={`${style.navbarBase} row row-cols-3 `}>
                    <div className={"col-3"}>
                        <Navbar.Brand  href="https://gotocure.com/"><img className={"img-fluid"} src="https://gotocure.com/images/logo.png" /></Navbar.Brand>
                    </div>
                    <div className=" col-6" >
                            <Form className={`d-flex position-relative `} style={{padding:"0 4em"}}>
                                <div className={style.searchBox}>
                                    <FormControl
                                        size="lg"
                                        type="search"
                                        className={style.searchBar}
                                        onBlur={(e)=>setShowSearch(false)}
                                        onFocus={(e)=>{e.target.value && setShowSearch(true)}}
                                        onChange={(e) => searchAll(e.target.value)}
                                    />
                                    <button className={style.Searchbutton}><FaSearch style={{fontWeight:"900"}} /></button>
                                    {
                                        showSearch && <div className={style.searchDropdown} >
                                            <ul >
                                                {clinics && clinics.map((clinic,index)=>(

                                                    <li key={index} className={style.searchTableItem}><a href="" className={style.searchTableItemUrl}><img
                                                        src={clinic.clinic_logo  ? `https://gotocure.com/images/logo/${clinic.clinic_logo}` : "https://gotocure.com/images/logo.png"} alt=""/><div className={style.searchTableItemTexts}>
                                                        {clinic.clinic_name}
                                                        <h6>Clinic</h6>
                                                        </div>
                                                        </a>
                                                    </li>
                                                ))}
                                                {doctors && doctors.map((doctor,index)=>(
                                                    <li key={index} className={style.searchTableItem}><a href="" className={style.searchTableItemUrl}><img
                                                        src={doctor.dr_image  ? `https://gotocure.com/images/clinic/doctor/${doctor.dr_image}` : "https://gotocure.com/images/logo.png"} alt=""/><div className={style.searchTableItemTexts}>
                                                        {doctor.dr_name}
                                                        <h6>Doctor</h6>
                                                    </div>
                                                    </a>
                                                    </li>
                                                ))}
                                                {categories && categories.map((category,index)=>(

                                                    <li key={index} className={style.searchTableItem}><a href={`https://gotocure.com/cure_detail?q=${encodeURIComponent(category.cat_text)}`} className={style.searchTableItemUrl}><div className={style.searchTableItemTexts}>
                                                        {category.cat_text}
                                                        <h6>Treatments</h6>
                                                    </div>
                                                    </a>
                                                    </li>
                                                ))}

                                            </ul>
                                        </div>
                                    }
                                </div>
                            </Form>

                    </div>
                    <div className={"col-3 my-auto"}>
                            <Nav className={style.dropdown}>
                                <BsGlobe className={"my-auto"}/>
                                <NavDropdown title={selectedLanguage[0].text} id="basic-nav-dropdown" onSelect={changeLanguage}
                                >
                                    {
                                        languages.map((language)=>(
                                            language.code !== lang &&
                                            <NavDropdown.Item eventKey={language.code} key={language.code}>{language.text} </NavDropdown.Item>

                                        )

                                        )
                                    }


                                </NavDropdown>
                                <NavDropdown title="Login" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="https://gotocure.com/login-1">{LangText("Bireysel Giriş")}</NavDropdown.Item>
                                    <NavDropdown.Item href="https://gotocure.com/login-2">{LangText("Kurumsal Giriş")}</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                    </div>
                </div>
                <Container className={`container ${style.mainMenu}`}>
                    <Nav defaultActiveKey="/home">
                        <Nav.Item>
                            <Nav.Link href={"https://gotocure.com"} >{LangText("Ana Sayfa")}</Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link href={"https://gotocure.com/cure"}>{LangText("Tedaviler")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href={"https://gotocure.com/clinic"}>{LangText("Klinikler")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href={"https://gotocure.com/kesfet"}>{LangText("Keşfet")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href={"https://gotocure.com/blogs"} className={`${style.activee}`}>{LangText("Blog")}</Nav.Link>
                        </Nav.Item>


                    </Nav>
                </Container>
            </Container>
            {/* header desktop end */}
            {/* header mobil */ }
            <Container className={"container d-flex d-md-none flex-column"}>
                <Row style={{display:"flex",}}>
                    <div className={" col-9 d-flex justify-content-between"}>
                        <Navbar.Brand  href="https://gotocure.com/"><img className={"img-fluid"} src="https://gotocure.com/images/logo.png" /></Navbar.Brand>
                    </div>
                    <div className={"col-3 d-flex justify-content-center"}>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" style={{display:"block",fontSize:"16px"}} onClick={()=>setShowMobileHeader(!showMobileHeader)}  />
                    </div>
                </Row>
                {
                    showMobileHeader &&

                <Row className={"mt-2"}>
                    <div className={`${style.navbarMobile} collapse navbar-collapse `}>
                        <ul className={"me-auto mb-2 mb-lg-0 text-center"}>
                            <li className={"nav-item position-relative"}>
                                <Form className={"d-flex"} >
                                    <InputGroup className="mb-3">
                                        <FormControl
                                            placeholder={"Search in blogs"}
                                            aria-describedby="basic-addon1"
                                            className={style.searchBarMobile}
                                            onBlur={(e)=>setShowSearch(false)}
                                            onFocus={(e)=>{e.target.value && setShowSearch(true)}}
                                            onChange={(e) => searchAll(e.target.value)}
                                        />
                                        <Button  variant="outline-success" id="button-addon1">
                                            <FaSearch/>
                                        </Button>
                                        {
                                            showSearch && <div className={style.searchDropdown} >
                                                <ul >
                                                    {clinics && clinics.map((clinic,index)=>(
                                                        <li key={index} className={style.searchTableItem}><a href="" className={style.searchTableItemUrl}><img
                                                            src={clinic.clinic_logo  ? `https://gotocure.com/images/logo/${clinic.clinic_logo}` : "https://gotocure.com/images/logo.png"} alt=""/><div className={style.searchTableItemTexts}>
                                                            {clinic.clinic_name}
                                                            <h6>Clinic</h6>
                                                        </div>
                                                        </a>
                                                        </li>
                                                    ))}
                                                    {doctors && doctors.map((doctor,index)=>(
                                                        <li key={index} className={style.searchTableItem}><a href="" className={style.searchTableItemUrl}><img
                                                            src={doctor.dr_image  ? `https://gotocure.com/images/clinic/doctor/${doctor.dr_image}` : "https://gotocure.com/images/logo.png"} alt=""/><div className={style.searchTableItemTexts}>
                                                            {doctor.dr_name}
                                                            <h6>Doctor</h6>
                                                        </div>
                                                        </a>
                                                        </li>
                                                    ))}
                                                    {categories && categories.map((category)=>(

                                                        <li className={style.searchTableItem}><a href={`https://gotocure.com/cure_detail?q=${encodeURIComponent(category.cat_text)}`} className={style.searchTableItemUrl}><div className={style.searchTableItemTexts}>
                                                            {category.cat_text}
                                                            <h6>Treatments</h6>
                                                        </div>
                                                        </a>
                                                        </li>
                                                    ))}

                                                </ul>
                                            </div>
                                        }

                                    </InputGroup>
                                </Form>
                            </li>
                            <li className={"nav-item"}>
                                <a className={`nav-link`} href={"https://gotocure.com"} >{LangText("Ana Sayfa")}</a>
                            </li>

                            <li className={"nav-item"}>
                                <a className={"nav-link"} href={"https://gotocure.com/cure"} >{LangText("Tedaviler")}</a>
                            </li>
                            <li className={"nav-item"}>
                                <a className={"nav-link"} href={"https://gotocure.com/clinic"} >{LangText("Klinikler")}</a>
                            </li>
                            <li className={"nav-item"}>
                                <a className={"nav-link"} href={"https://gotocure.com/kesfet"}>{LangText("Keşfet")}</a>
                            </li>
                            <li className={"nav-item"}>
                                <a className={`nav-link ${style.activee}`}  href={"https://gotocure.com/blogs"}>{LangText("Blog")}</a>
                            </li>
                            <li className={"nav-item mx-auto"}>
                                <Nav>
                                    <BsGlobe className={"my-auto"}/>
                                    <NavDropdown title={selectedLanguage[0].text} id="basic-nav-dropdown" onSelect={changeLanguage}
                                    >
                                        {
                                            languages.map((language)=>(
                                                    language.code !== lang &&
                                                    <NavDropdown.Item eventKey={language.code} key={language.code}>{language.text} </NavDropdown.Item>

                                                )

                                            )
                                        }
                                    </NavDropdown>

                                </Nav>
                            </li>
                            <li className={"nav-item mx-auto"}>
                                <NavDropdown title="Login" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="https://gotocure.com/login-1">{LangText("Bireysel Giriş")}</NavDropdown.Item>
                                    <NavDropdown.Item href="https://gotocure.com/login-2">{LangText("Kurumsal Giriş")}</NavDropdown.Item>
                                </NavDropdown>
                            </li>

                        </ul>
                    </div>
                </Row>
                }
            </Container>
            {/* header mobil end */ }
        </Navbar>
    );
}

export default Index;