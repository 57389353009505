import './App.css';
import {BrowserRouter as Router, Route, Routes, useLocation} from "react-router-dom";
import {useLayoutEffect} from 'react';
import Blog from "./pages/Blog";
import BlogDetail from "./pages/BlogDetail";
import BlogFilter from "./pages/BlogFilter";
import Error from "./pages/Error";
import Header from "./components/Header";
import ReactGA from 'react-ga';
import {LangProvider} from "./states/LangContext";
import {MainProvider} from "./states/MainContext";

ReactGA.initialize('UA-198291018-2');
ReactGA.pageview(window.location.pathname + window.location.search);

const Wrapper = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
}
function App() {

  return (
      <LangProvider>
          <MainProvider>
              <Router basename={`/blogs/`}>
                      <Header />
                          <Wrapper>
                              <Routes >
                                  <Route path="/" element={<Blog />} />
                                  <Route path="/:lang" element={<Blog />} />
                                  <Route path="/:lang/:id/:name" element={<BlogDetail />} />
                                  <Route path="/:lang/category/:category" element={<BlogFilter />} />
                                  <Route path="/error" element={<Error />} />
                                  <Route path="*" element={<Error />} />
                              </Routes>
                          </Wrapper>
              </Router>
          </MainProvider>
      </LangProvider>


  );
}

export default App;
