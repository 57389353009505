import React from 'react';
import  style from "./card.module.css";
import {Col, Row} from "react-bootstrap";
import {IoMdArrowRoundForward} from "react-icons/io";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LangText from "../../hooks/langText";
import 'react-lazy-load-image-component/src/effects/blur.css';
import {useLang} from "../../states/LangContext";
function Index(props) {
    const {lang} = useLang()
    return (
        <div className={style.mainCard}>
            <Row>
                {
                    props.img ? <Col md={"3"} xs={"12"} >
                        <LazyLoadImage
                            alt={props.img.alt_text}
                            src={props.img.guid.rendered}
                            effect="blur"
                        />
                    </Col> : <Col md={"3"} xs={"12"} >
                        <LazyLoadImage
                            alt="logo"
                            src="https://gotocure.com/images/logo3.png"
                            effect="blur"

                        />
                    </Col>
                }

                <Col md={"9"} xs={"12"} >
                    <Link to={`/${lang}/${props.blogs.id}/${props.blogs.slug}`} className={style.blogTitle}> <h3 className={"mt-2"}>
                        {props.blogs.title.rendered}
                    </h3></Link>
                    <div dangerouslySetInnerHTML={{__html:props.blogs.excerpt.rendered}}></div>
                    <Link to={`/${lang}/${props.blogs.id}/${props.blogs.slug}`} className={style.readMoreButton}>{LangText("Daha fazla oku")} <IoMdArrowRoundForward /></Link>
                </Col>
            </Row>


        </div>
    );
}

export default Index;