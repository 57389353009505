import {useLang} from "../states/LangContext";


function LangText (text){
    const {langMessage} = useLang()

    return langMessage[text] ? langMessage[text] : text
}


export default LangText