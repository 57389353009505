import React from 'react';
import style from './OfferBox.module.css'
import {Col, Row} from "react-bootstrap";
import LangText from "../../hooks/langText";

const Index = () => {
    return (
        <div className={style.box}>
            <Row >
                <Col xs={8} md={8} className={style.text} >{LangText("Gotocure'den kliniklere ulaşın")}</Col>
                <Col xs={4} md={4} className={"d-flex align-items-center justify-content-center"}><a href={"https://gotocure.com/get-offer?c=tr"} className={`btn ${style.button}`}>{LangText("Teklif Al")}</a> </Col>
            </Row>
        </div>
    );
};

export default Index;