import Card from "./Card";
import React, {useState} from "react";

export default function Items({ currentItems,media }) {


    return (
        <>
            {currentItems &&
                currentItems.map((blogs,index) => {
                    let img = media.find(media => media.id === blogs?.featured_media);

                    return <Card key={index} blogs={blogs} img ={img}/>
                })}
        </>
    );
}